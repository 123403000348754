<template>
  <section class="header">
    <div class="content box frsbc">
      <h1 class="mr100" :style="{'cursor': type == 'search' ? 'default' : 'pointer'}" @click="loginClick">
        <img src="~assets/images/logo.png">
      </h1>

      <div class="search_content" v-if="type === 'search' || type === 'personalCenter'">
        <div class="search frsac">
          <div class="search_input f">
            <div class="input frsbc p015">
              <div class="search_icon">
                <img src="~assets/images/search.png">
              </div>
              <input class="m05" type="text" v-model="keyword" placeholder="请输入书名查找" @keyup.enter="searchClick">
              <div class="delete_icon" @click="clearKeyword" v-if="keyword">
                <img src="~assets/images/delete.png">
              </div>
            </div>
            <div class="buttom" @click="searchClick">搜索</div>
          </div>
          <div class="my_order m015" @click="goMyOrder" v-if="type !== 'personalCenter'">我的订单</div>
          <div class="senior_search" @click="seniorSearch" v-if="type !== 'personalCenter'">高级搜索</div>
        </div>
        <div class="hot_word mt15 f">
          <div class="mr15" v-for="item in hotWord" :key="item.id" @click="chooseHotWord(item)">
            {{item}}
          </div>
        </div>
      </div>
      <div class="other" v-else>
        <img :src="type === 'purchaseOrder' ? 'https://hullobuket.oss-cn-shenzhen.aliyuncs.com/dev/user/1655196061564.png' : type === 'settlementPage' ? 'https://hullobuket.oss-cn-shenzhen.aliyuncs.com/dev/user/1655196425618.png' : 'https://hullobuket.oss-cn-shenzhen.aliyuncs.com/dev/user/1655196441843.png'">
      </div>

      <div class="sun_code ml100">
        <el-popover trigger="hover" width="300">
          <p style="text-align: center; font-weight: bold; color: #000;">微信小程序太阳码</p>
          <img src="~assets/images/sun-code.jpg">
          <img slot="reference" src="~assets/images/sun-code.jpg">
        </el-popover>
      </div>
    </div>

    <SeniorSearch :visible.sync="seniorSearchVisible" @search="searchHandle" />
  </section>
</template>

<script>
  import SeniorSearch from './components/SeniorSearch.vue';

  export default {
    components: {
      SeniorSearch
    },

    props: {
      type: {
        type: String,
        default: 'home'
      },
      hotWord: {
        type: Array,
        default: () => []
      }
    },

    data() {
      return {
        keyword: '',
        seniorSearchVisible: false
      }
    },

    computed: {
      token() {
        return this.$store.state.user.token;
      }
    },

    methods: {
      loginClick() {
        this.$router.push({
          name: 'home'
        });
      },
      searchClick() {
        if(this.type === 'personalCenter' || this.type === 'goodsDetail') {
          let routeUrl = this.$router.resolve({
            path: '/home',
            query: {
              keyword: this.keyword
            }
          });
          window.open(routeUrl.href, '_blank');
          return;
        }
        this.$emit('ordinarySearch', this.keyword);
      },
      chooseHotWord(hotWord) {
        this.keyword = hotWord;
        this.searchClick();
      },
      clearKeyword() {
        this.keyword = '';
      },
      goMyOrder() {
        if(!this.token) {
          this.$router.push({
            name: 'login'
          });
          return;
        }
        this.$router.push({
          name: 'personalCenter'
        });
      },
      seniorSearch() {
        this.seniorSearchVisible = true;
      },
      searchHandle(formData) {
        this.$emit('search', formData);
      }
    }
  }
</script>

<style lang="less" scoped>
  .header {
    height: 150px;
    border-bottom: 8px solid #f2f2f2;
    .content {
      height: 100%;
      h1 {
        width: 184px;
        height: 55px;
      }

      .search_content {
        flex: 1;
        .search {
          width: 100%;
          .search_input {
            width: 100%;
            height: 40px;
            border: 2px solid var(--colored6d00);
            .input {
              flex: 1;
              .search_icon {
                width: 17px;
                height: 17px;
              }
              input {
                flex: 1;
                font-size: var(--fontSize12);
                color: var(--color999);
                font-weight: 500;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .delete_icon {
                width: 20px;
                height: 20px;
                cursor: pointer;
              }
            }
            .buttom {
              width: 100px;
              height: 100%;
              background: var(--colored6d00);
              font-size: var(--fontSize16);
              color: var(--colorfff);
              font-weight: bold;
              text-align: center;
              line-height: 36px;
              cursor: pointer;
            }
          }
          .my_order {
            width: 120px;
            height: 40px;
            background: var(--colorfff);
            border: 1px solid var(--colored6d00);
            font-size: var(--fontSize16);
            color: var(--colored6d00);
            font-weight: bold;
            text-align: center;
            line-height: 38px;
            cursor: pointer;
          }
          .senior_search {
            font-size: var(--fontSize16);
            color: var(--color1890ff);
            white-space: nowrap;
            cursor: pointer;
          }
        }
        .hot_word {
          div {
            font-size: var(--fontSize12);
            color: var(--color666);
            font-weight: 500;
            cursor: pointer;
          }
        }
      }
      .other {
        width: 765px;
        height: 100px;
      }

      .sun_code {
        width: 104px;
        height: 104px;
      }
    }
  }
</style>
