<template>
  <section class="logout_popup_window">
    <el-dialog width="15%" center :visible="visible" :show-close="false">
      <div>是否确定退出当前账户?</div>
      
      <template slot="footer">
        <el-button type="primary" @click="determineClick">确定</el-button>
        <el-button @click="cancelClick">取消</el-button>
      </template>
    </el-dialog>
  </section>
</template>

<script>
  export default {
    props: {
      visible: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      determineClick() {
        this.$emit('determine');
        this.cancelClick();
      },
      cancelClick() {
        this.$emit('update:visible', false);
      }
    }
  }
</script>

<style lang="less" scoped>
  .logout_popup_window {
    div {
      font-size: var(--fontSize20);
      color: var(--color000);
      font-weight: bold;
      text-align: center;
    }
  }
</style>
