<template>
  <section class="senior_search">
    <el-dialog :visible="visible" width="30%" @close="cancelClick">
      <template slot="title">
        <div class="title">高级搜索</div>
      </template>

      <el-form ref="form" :model="formData" label-width="150px">
        <el-form-item label="商品名称">
          <el-input class="input300" type="text" v-model="formData.keyword"></el-input>
        </el-form-item>
        <el-form-item label="ISBN">
          <el-input class="input300" type="text" v-model="formData.isbn"></el-input>
        </el-form-item>
        <el-form-item label="联盟折扣" v-if="unionFlag == 1">
          <el-select class="select100" v-model="formData.startUnionDiscount">
            <el-option v-for="(item, index) in discountOptions" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
          至
          <el-select class="select100" v-model="formData.endUnionDiscount">
            <el-option v-for="(item, index) in discountOptions" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="批发折扣" v-else>
          <el-select class="select100" v-model="formData.startDiscountTrade">
            <el-option v-for="(item, index) in discountOptions" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
          至
          <el-select class="select100" v-model="formData.endDiscountTrade">
            <el-option v-for="(item, index) in discountOptions" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联盟价" v-if="unionFlag == 1">
          <el-input class="input100" type="text" v-model="formData.startUnionPrice"></el-input>
          至
          <el-input class="input100" type="text" v-model="formData.endUnionPrice"></el-input>
        </el-form-item>
        <el-form-item label="批发价" v-else>
          <el-input class="input100" type="text" v-model="formData.startTradePrice"></el-input>
          至
          <el-input class="input100" type="text" v-model="formData.endTradePrice"></el-input>
        </el-form-item>
      </el-form>

      <template slot="footer">
        <el-button @click="resetCondition">重置条件</el-button>
        <el-button type="primary" @click="startSearch">开始搜索</el-button>
      </template>
    </el-dialog>
  </section>
</template>

<script>
import {
  getUnionFlag
} from 'utils/user.js';
  export default {
    props: {
      visible: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        unionFlag: getUnionFlag(),
        discountOptions: [
          {label: '0.1折', value: 0.1},
          {label: '1折', value: 1},
          {label: '2折', value: 2},
          {label: '3折', value: 3},
          {label: '4折', value: 4},
          {label: '5折', value: 5},
          {label: '6折', value: 6},
          {label: '7折', value: 7},
          {label: '8折', value: 8},
          {label: '9折', value: 9},
          {label: '9.9折', value: 9.9}
        ],
        formData: {
          keyword: '',
          isbn: '',
          startDiscountTrade: '',
          endDiscountTrade: '',
          startTradePrice: '',
          endTradePrice: '',
          startUnionDiscount: '',
          endUnionDiscount: '',
          startUnionPrice: '',
          endUnionPrice: '',
        }
      }
    },

    methods: {
      resetCondition() {
        this.formData = this.$options.data().formData;
      },
      startSearch() {
        if(this.unionFlag == 1) {
          if(this.formData.startUnionDiscount > this.formData.endUnionDiscount) {
            this.$message({
              type: 'warning',
              message: '最小联盟折扣不能大于最大联盟折扣！'
            });
            return;
          }
          if(+this.formData.startUnionPrice > +this.formData.endUnionPrice) {
            this.$message({
              type: 'warning',
              message: '最小联盟价不能大于最大联盟价！'
            });
            return;
          }
        }else {
          if(this.formData.startDiscountTrade > this.formData.endDiscountTrade) {
            this.$message({
              type: 'warning',
              message: '最小直批折扣不能大于最大直批折扣！'
            });
            return;
          }
          if(+this.formData.startTradePrice > +this.formData.endTradePrice) {
            this.$message({
              type: 'warning',
              message: '最小直批价不能大于最大直批价！'
            });
            return;
          }
        }
        this.$emit('search', this.formData);
        this.cancelClick();
      },
      cancelClick() {
        this.$emit('update:visible', false);
        this.resetCondition();
      }
    }
  }
</script>

<style lang="less" scoped>
  .senior_search {
    .title {
      margin: 15px 0 0 15px;
      font-size: var(--fontSize20);
      color: var(--color000);
      font-weight: bold;
    }
  }
</style>
