<template>
  <section class="nav_bar">
    <div class="content box frsbc">
      <div class="left frfsc">
        <div class="welcome_word mr10 frfsc">
          您好，{{!token ? '欢迎来到青葫芦图书在线直批' : userName}}<img class="m05" :src="memberLogo" v-if="token">
        </div>
        <div class="operation" @click="operation">【{{!token ? '请登录' : '退出'}}】</div>
      </div>
      <div class="right">
        <ul class="f">
          <li @click="jump('home')">首页</li>
          <li class="m015">|</li>
          <li @click="jump('purchaseOrder')">我的采购单</li>
          <li class="m015">|</li>
          <li @click="jump('personalCenter')">我的订单</li>
          <li class="m015">|</li>
          <li @click="jump('personalCenter')">个人中心</li>
          <li class="m015">|</li>
          <li>微信小程序</li>
        </ul>
      </div>
    </div>

    <LogoutPopupWindow :visible.sync="logoutVisible" @determine="logout" />
  </section>
</template>

<script>
  import LogoutPopupWindow from './components/LogoutPopupWindow.vue';
  import {getUserName, removeToken, getRegularFlag, removePcLoginKey, removeRegularFlag, removeUnionFlag} from 'utils/user.js';

  export default {
    components: {
      LogoutPopupWindow
    },

    data() {
      return {
        userName: getUserName(),
        regularFlag: getRegularFlag(),
        logoutVisible: false
      }
    },

    computed: {
      token() {
        return this.$store.state.user.token;
      },
      memberLogo() {
        return this.regularFlag == 1 ? 'https://hullobuket.oss-cn-shenzhen.aliyuncs.com/user/1655800364696.png' :
               this.regularFlag == 2 ? 'https://hullobuket.oss-cn-shenzhen.aliyuncs.com/user/1655800390418.png' : 'https://hullobuket.oss-cn-shenzhen.aliyuncs.com/user/1655800407177.png';
      }
    },

    methods: {
      operation() {
        if(!this.token) {
          this.login();
          return;
        }
        this.logoutVisible = true;
      },
      login() {
        this.$router.push({
          name: 'login'
        });
      },
      logout() {
        removeToken();
        removePcLoginKey();
        removeRegularFlag();
        removeUnionFlag();
        this.$store.commit('SET_TOKEN', '');
        this.$store.commit('SET_PCLOGINKEY', '');
        this.$store.commit('SET_REGULARFLAG', '');
        this.$store.commit('SET_UNIONFLAG', '');
        this.login();
      },
      jump(name) {
        if(!this.token && name != 'home') {
          this.login();
          return;
        }
        this.$router.push({
          name
        });
      }
    }
  }
</script>

<style lang="less" scoped>
  .nav_bar {
    height: 50px;
    background: var(--colorfafafa);
    .content {
      height: 100%;
      .left {
        .welcome_word {
          font-size: 14px;
          color: var(--color333);
          font-weight: 500;
          img {
            width: 80px;
            height: 20px;
          }
        }
        .operation {
          font-size: 14px;
          color: var(--color1890ff);
          font-weight: 500;
          cursor: pointer;
        }
      }
      .right {
        ul {
          li {
            font-size: 14px;
            color: var(--color333);
            font-weight: 500;
            cursor: pointer;
          }
        }
      }
    }
  }
</style>
